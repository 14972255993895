// extracted by mini-css-extract-plugin
export var agentTag = "UserProjectDetailsOwner__agentTag__HqMt_";
export var column = "UserProjectDetailsOwner__column__pUbBx";
export var container = "UserProjectDetailsOwner__container__IftxR";
export var designationText = "UserProjectDetailsOwner__designationText__rdG6L";
export var flex = "UserProjectDetailsOwner__flex__b0NiJ";
export var flexColumn = "UserProjectDetailsOwner__flexColumn__bOId0";
export var fullName = "UserProjectDetailsOwner__fullName__K6t8u";
export var gap1 = "UserProjectDetailsOwner__gap1__AtgF6";
export var gap2 = "UserProjectDetailsOwner__gap2__EfnxR";
export var gap3 = "UserProjectDetailsOwner__gap3__xcphP";
export var gap4 = "UserProjectDetailsOwner__gap4__Ts4K3";
export var gap5 = "UserProjectDetailsOwner__gap5__BoHhu";
export var heading = "UserProjectDetailsOwner__heading__vXih8";
export var imageWrapper = "UserProjectDetailsOwner__imageWrapper__uS24Z";
export var nameAndTagWrapper = "UserProjectDetailsOwner__nameAndTagWrapper__NIDZi";
export var ownerDescriptionWrapper = "UserProjectDetailsOwner__ownerDescriptionWrapper__pNqtM";
export var ownerDetailsWrapper = "UserProjectDetailsOwner__ownerDetailsWrapper__dYQbx";
export var row = "UserProjectDetailsOwner__row__HmTLF";