// extracted by mini-css-extract-plugin
export var column = "UserProjectDetailsDocuments__column__C9CcT";
export var container = "UserProjectDetailsDocuments__container__ssCbO";
export var documentsHeading = "UserProjectDetailsDocuments__documentsHeading__zHsLg";
export var downloadButton = "UserProjectDetailsDocuments__downloadButton__TdSdJ";
export var fileName = "UserProjectDetailsDocuments__fileName__zEbce";
export var fileNameAndIconWrapper = "UserProjectDetailsDocuments__fileNameAndIconWrapper__O_C00";
export var flex = "UserProjectDetailsDocuments__flex__E1aK5";
export var flexColumn = "UserProjectDetailsDocuments__flexColumn__tH2ti";
export var gap1 = "UserProjectDetailsDocuments__gap1__XT_80";
export var gap2 = "UserProjectDetailsDocuments__gap2__eBxUt";
export var gap3 = "UserProjectDetailsDocuments__gap3__QAx42";
export var gap4 = "UserProjectDetailsDocuments__gap4__HIzfg";
export var gap5 = "UserProjectDetailsDocuments__gap5__GFbOi";
export var icon = "UserProjectDetailsDocuments__icon__kFi4o";
export var row = "UserProjectDetailsDocuments__row__nn7YH";