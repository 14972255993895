// extracted by mini-css-extract-plugin
export var column = "ProjectEstimatedDates__column__haI8H";
export var container = "ProjectEstimatedDates__container__Dq2iz";
export var flex = "ProjectEstimatedDates__flex___DlIr";
export var flexColumn = "ProjectEstimatedDates__flexColumn__oyMlR";
export var gap1 = "ProjectEstimatedDates__gap1__Jrsk8";
export var gap2 = "ProjectEstimatedDates__gap2__lsgtZ";
export var gap3 = "ProjectEstimatedDates__gap3__nM5pR";
export var gap4 = "ProjectEstimatedDates__gap4__UJu3I";
export var gap5 = "ProjectEstimatedDates__gap5___B7po";
export var panelHeadingDates = "ProjectEstimatedDates__panelHeadingDates__kuCgs";
export var panelHeadingText = "ProjectEstimatedDates__panelHeadingText__evXYr";
export var requiredProductDate = "ProjectEstimatedDates__requiredProductDate__YdpYb";
export var requiredProductHeading = "ProjectEstimatedDates__requiredProductHeading__nsc3G";
export var requiredProductSubHeading = "ProjectEstimatedDates__requiredProductSubHeading__PjTJF";
export var rightArrow = "ProjectEstimatedDates__rightArrow__VThsS";
export var row = "ProjectEstimatedDates__row__I1lDg";