// extracted by mini-css-extract-plugin
export var additionalNotes = "ProjectRequiredProducts_CheckmarkableOptions__additionalNotes__uo4kg";
export var checkboxes = "ProjectRequiredProducts_CheckmarkableOptions__checkboxes__VNNuu";
export var checkboxesContainer = "ProjectRequiredProducts_CheckmarkableOptions__checkboxesContainer__xBX7N";
export var column = "ProjectRequiredProducts_CheckmarkableOptions__column__nCuHE";
export var descriptionHeading = "ProjectRequiredProducts_CheckmarkableOptions__descriptionHeading__LT0qx";
export var flex = "ProjectRequiredProducts_CheckmarkableOptions__flex__v0nzm";
export var flexColumn = "ProjectRequiredProducts_CheckmarkableOptions__flexColumn__s7sUO";
export var gap1 = "ProjectRequiredProducts_CheckmarkableOptions__gap1__tZgnj";
export var gap2 = "ProjectRequiredProducts_CheckmarkableOptions__gap2__yI8Vo";
export var gap3 = "ProjectRequiredProducts_CheckmarkableOptions__gap3__Nu_MV";
export var gap4 = "ProjectRequiredProducts_CheckmarkableOptions__gap4__Pl_HO";
export var gap5 = "ProjectRequiredProducts_CheckmarkableOptions__gap5__eraNF";
export var lightBackground = "ProjectRequiredProducts_CheckmarkableOptions__lightBackground__QWKDx";
export var notesContainer = "ProjectRequiredProducts_CheckmarkableOptions__notesContainer___M9jK";
export var panel = "ProjectRequiredProducts_CheckmarkableOptions__panel__izyb7";
export var panelBody = "ProjectRequiredProducts_CheckmarkableOptions__panelBody__Qj92e";
export var panelHeading = "ProjectRequiredProducts_CheckmarkableOptions__panelHeading___78s8";
export var productName = "ProjectRequiredProducts_CheckmarkableOptions__productName__CIJUv";
export var productRow = "ProjectRequiredProducts_CheckmarkableOptions__productRow__iA1vm";
export var productServiceLabel = "ProjectRequiredProducts_CheckmarkableOptions__productServiceLabel__CcQaN";
export var productServiceValue = "ProjectRequiredProducts_CheckmarkableOptions__productServiceValue__HpVKr";
export var productsServiceWrapper = "ProjectRequiredProducts_CheckmarkableOptions__productsServiceWrapper__VzrxK";
export var propertiesContainer = "ProjectRequiredProducts_CheckmarkableOptions__propertiesContainer__FLFRF";
export var propertyLeft = "ProjectRequiredProducts_CheckmarkableOptions__propertyLeft__MnarB";
export var propertyRight = "ProjectRequiredProducts_CheckmarkableOptions__propertyRight__mtdbT";
export var row = "ProjectRequiredProducts_CheckmarkableOptions__row__i07_h";
export var stayShaded = "ProjectRequiredProducts_CheckmarkableOptions__stayShaded__rU7Qa";
export var switchLight = "ProjectRequiredProducts_CheckmarkableOptions__switchLight__QfRgY";
export var switchShaded = "ProjectRequiredProducts_CheckmarkableOptions__switchShaded__WnrmQ";
export var tickmarkIcon = "ProjectRequiredProducts_CheckmarkableOptions__tickmarkIcon__UGWK6";
export var withColon = "ProjectRequiredProducts_CheckmarkableOptions__withColon__qGnOO";